<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Input placeholder="请输入账户名称" v-model="operationArr.name"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker placeholder="时间查询" style="width: 100%;" v-model="operationArr.create_time"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="worlkColumns" :data="data6"></Table>
    <br/>
    <Page :total="tablePage.total" :current="operationArr.page" style="text-align:right" show-elevator show-total @on-change="pageChange"
          :page-size="operationArr.limit"/>
    <Modal
        title="Title"
        v-model="showModel"
        :mask-closable="false"
        width="600"
        class-name="vertical-center-modal">
      <Form :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="用户账户">
          <Input v-model="formItem.input" placeholder="Enter something..."></Input>
        </FormItem>
        <FormItem label="用户密码">
          <Input v-model="formItem.input" placeholder="Enter something..."></Input>
        </FormItem>
        <FormItem label="用户名称">
          <Input v-model="formItem.input" placeholder="Enter something..."></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import {operationList} from "../../api/operation";
import moment from "moment";
export default {
  data () {
    return {
      worlkColumns:  [
        {
          title: '账户名称',
          render: (h, params) => {
            return h('div', [
              h('div' , params.row.admininfo.real_name)
            ]);
          }
        },
        {
          title: '真实账户',
          render: (h, params) => {
            return h('div', [
              h('div' , params.row.admininfo.account)
            ]);
          }
        },
        {
          title: '操作标题',
          key: 'title'
        },
        {
          title: '操作记录',
          key: 'content'
        },
        {
          title: '操作时间',
          key: 'created_at'
        }
      ],
      data6: [],
      showModel:false,
      formItem:{
        input:''
      },
      operationArr:{
        limit: 10,
        page: 1,
        name:'',
        create_time:'',
        type:'1'
      },
      tablePage:{
        total:0
      }
    }
  },
  mounted(){
  },
  created() {
    this.getList()
  },
  methods: {
    getList(){
      operationList(this.operationArr).then(res=>{
        this.data6 = res.data.data || {}
        this.tablePage.total = res.data.total
      }).catch(res => {
        this.$Message.error(res.msg)
        this.data6 = []
      })
    },
    pageChange(index) {
      this.operationArr.page = index
      this.getList()
    },
    searchList(){
      this.operationArr.create_time = moment(this.operationArr.create_time).format('YYYY-MM-DD')
      if(this.operationArr.create_time == 'Invalid date'){
        this.operationArr.create_time = ''
      }
      this.getList()
    },
    addUser(){
      this.showModel=true

    }
  }
}
</script>
